"use client";

import styled from "styled-components";

import { getEntries } from "@/util/core";

export const ModalWrapper = styled.div`
  position: absolute;
  // background: var(--background-overflay);
  // backdrop-filter: blur(8px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 999;
  transition: 300ms ease-out;
  * {
    transition: 300ms ease-out;
  }

  @media (min-width: 1024px) {
    width: 378px;
    right: 0;
    left: auto;
    height: 410px;
    background: transparent;
    backdrop-filter: none;

    display: none;
    &.visible {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    opacity: 0;
    transition: all 200ms ease-in;
    z-index: -1;
    &.visible {
      opacity: 1;
      z-index: 999;
    }
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%;
  bottom: 0;
  padding: 32px 24px 24px;
  color: var(--text-primary);
  overflow: scroll;
  max-height: calc(100vh - 120px);
  box-shadow: 0px 8px 32px rgba(51, 20, 0, 0.16);
  transform: translateX(-50%);
  background: var(--background-elevated);

  .action,
  .headerM,
  .headerS {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }

  @media (max-width: 1024px) {
    transform: translate(-50%, 100%);
    transition: all 300ms ease-in;
    background: var(--background-elevated);

    &.visible {
      transform: translate(-50%, 0);
    }
  }

  .no-assets {
    text-align: left;
    & > * {
      margin: 0;
    }
    & > div:first-child {
      font-size: 20px;
      margin-bottom: 4px;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    left: 50%;
    border-radius: 2px;
    background: var(--fill-secondary);
    top: 10px;
    transform: translateX(-50%);
    width: 32px;
    height: 4px;
    @media (min-width: 1024px) {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    max-width: 380px;
    width: 380px;
    border-radius: 32px;
    top: 80px;
    bottom: auto;
    transform: translateX(0);
    left: auto;
    right: 24px;
    max-height: 600px;
    overflow: scroll;
  }

  @media (max-width: 1024px) {
    border-radius: 32px 32px 0 0;
  }

  &::-webkit-scrollbar {
    opacity: 0;
    background-color: transparent;
    width: 0;
  }
  &::-webkit-scrollbar:horizontal {
    opacity: 0;
    height: 0px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
    background: transparent;
    -webkit-box-shadow: inset 0 0 0 transparent;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    opacity: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 0;
  }
`;

export const TestNetworks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NetworkRow = styled.a<{ $clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
  width: 100%;
  letter-spacing: 0.16px;
  line-height: 1.5;
  color: var(--text-primary);
  ${({ $clickable }) => $clickable && "cursor: pointer;"}
`;

export const NetworkLogo = styled.div`
  background: var(--color-brand-marginly);
  border-radius: 50%;
  padding: 12px;
  width: 48px;
  height: 48px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const NetworkModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .headerM {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }
  .sm {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }

  @media (max-width: 1024px) {
    .headerM {
      font-size: 24px;
    }
  }
`;

export const Assets = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  padding: 12px 24px 16px;
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  width: 100%;
  text-align: left;
`;

const ASSET_COLORS = {
  cyan: "#2D42FC",
  violet: "#627eea",
  darkviolet: "#7b3fe4",
  darkblue: "#2775CA",
  linkblue: "#3251c0",
  blue: "#3474e2",
  arbblue: "#13aaff",
  green: "#2f855a",
  orange: "#F7931A",
  aubergine: "#EFF1FC",
  default: "var(--fill-secondary)",
  rdntgradient:
    "linear-gradient(180deg, #00ffaa 9%, #56a2cc 50%, #5e04f9 100%)",
  gradient: "linear-gradient(180deg, #4af892 0%, #06b6e4 100%)",
};

export const genBackgroundColorClass = (selector: string) =>
  getEntries(ASSET_COLORS)
    .map(
      ([name, color]) => `
          &.color-${name}${selector} {
            background: ${color};
          }
        `,
    )
    .join("\n");

export const AssetItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
  margin: 8px 0;

  .checkmark {
    display: none;
  }

  &.selected .checkmark {
    display: block;
  }

  p.body {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 0;
    white-space: nowrap;
  }

  .button {
    margin-left: auto;
  }

  .thumbnail {
    border-radius: var(--rounding-radius-xs, 8px);
    margin: 8px 0;
    overflow: hidden;

    > img {
      width: 48px;
      height: 48px;
    }

    ${genBackgroundColorClass("")}
  }

  .resticon {
    width: 48px;
    height: 48px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const NetworkArrow = styled.div`
  margin-left: auto;
  display: grid;
  place-items: center;
`;

export const Subheading = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: var(--text-secondary);
  opacity: 0.64;
`;

export const ThemeButton = styled.button`
  border: 0;
  outline: 0;
  background: var(--fill-secondary);
  display: flex;
  align-items: center;
  border-radius: 24px;
  gap: 16px;
  width: 100%;
  cursor: pointer;
  color: var(--text-primary);
  height: 80px;
  padding: var(--spacing-space-16, 16px) var(--spacing-space-24, 24px);
`;

export const ThemeButtonRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.16px;
  color: var(--text-secondary);
`;

export const ThemeModalHeading = styled.div`
  position: relative;
  text-align: center;
  font-size: 20px;
  font-variation-settings: "wght" 700;
  line-height: 1.2;
  letter-spacing: 0.4px;
`;

export const BackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 14px;
  z-index: 9;
`;

export const ThemeSelect = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 14px 0;
  width: 100%;
  background: transparent;
  color: var(--text-primary);
  height: 48px;
  gap: 16px;
  img {
    filter: invert(var(--svg-invert));
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  & + & {
    margin-top: 16px;
  }
`;

export const Percents = styled.div`
  margin-top: 2px;
  text-align: left;
  p {
    color: var(--text-negative, #e54796);
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: 0.5px;
  }
  &.up p {
    color: var(--text-positive, #45be33);
  }
`;

export const SwipeableWrapper = styled.div`
  // position: absolute;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  & > div {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
  }

  @media (max-width: 1024px) {
    background: var(--background-overflay);
    width: 100vw;
    height: 100vh;
    transition: 300ms linear;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    & > div {
      transform: translate(0%, calc(0%));
    }
    &.hidden {
      opacity: 0;
    }
    .hidden {
      transition: 300ms ease-out;
      transform: translate(0%, calc(100%));
    }
  }
`;
