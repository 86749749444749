"use client";
import React from "react";

import { Container, Content } from "./navigation.styled";

const Navigation: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ className, children }) => {
  return (
    <>
      <Container className={`ui-navigation ${className ?? ""}`}>
        <Content className="ui-navigation__content">{children}</Content>
      </Container>
    </>
  );
};

export default Navigation;
