"use client";

import { cssVar } from "@marginly/ui/util/style";
import styled from "styled-components";

import NavUI from "@/components/ui/navigation/navigation";

export const Logo = styled.a`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  width: 26px;
  height: 32px;
  background-image: var(--mobile-logo);
  @media (min-width: 1024px) {
    width: 127px;
    height: 24px;

    background-image: var(--logo);
  }
`;

const SignInButton = styled.button`
  border-radius: 16px;
  background: #f7f3f2;
  padding: 5px 12px 7px;
  letter-spacing: 0.03em;
  color: #000000;
  font-family: "PlusJakartaSans";
  font-variation-settings: "wght" 700;
  font-size: 16px;
  line-height: 20px;
  border: 0;
  outline: 0;
`;

export const ButtonsRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Nav = styled(NavUI)`
  z-index: 1502;
  border-radius: 0;
  padding: 0;
  transition: 300ms ease-out;
  /* background: var(--background-primary); */
  /* background: transparent; */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: var(--header);

  &.airdrop {
    background: transparent;
    &.scrolled {
      background: transparent;
    }
  }

  .action,
  .headerS {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }

  &.warn {
    background: var(--background-negative);
    ${SignInButton} {
      background: #f5e5ea;
    }
  }
  &.good {
    background: var(--background-positive);
    ${SignInButton} {
      background: #f0faeb;
    }
  }
  &.home {
    background: var(--background-primary);
  }

  .ui-navigation__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 22px;
    max-width: none;
    min-height: 56px;
  }

  &.scrolled {
    /* background: ${cssVar("--background-secondary")}; */
  }

  &.promo {
    background: transparent;
    &.scrolled {
      background: ${cssVar("--background-secondary")};
    }
  }

  .selected-network.md {
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
      filter: invert(var(--svg-invert));
    }
    p {
      color: var(--text-primary);
    }
  }

  @media (max-width: 1024px) {
    .md,
    .md.icon {
      border-radius: var(--rounding-radius-m, 16px);
      height: 32px;
      padding: 4px 12px 4px 6px;
    }
    .applink {
      padding: 4px 12px;
    }
  }

  @media (min-width: 1024px) {
    padding: 19px 0;
    &.promo {
      &.scrolled {
        background: transparent;
      }
    }
  }
`;

export const Network = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
  cursor: pointer;

  .icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f3f2;
    border-radius: 50%;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  .checked {
    display: none;
    margin-left: auto;
  }

  &.selected {
    .checked {
      display: unset;
    }
  }

  &.test {
    opacity: 0;
    pointer-events: none;
    transition: opacity cubic-bezier(0, 1.23, 0.18, 0.91) 500ms;
    position: absolute;
    &.visible {
      opacity: 1;
      pointer-events: all;
      transition: opacity cubic-bezier(1, -0.01, 0.87, 0.84) 400ms;
      position: static;
    }
  }

  @media (max-width: 1024px) {
    &.test {
      height: 0px;
      transition: opacity cubic-bezier(0, 1.23, 0.18, 0.91) 500ms,
        height linear 500ms;
      padding: 0;
      &.visible {
        height: 64px;
        transition: opacity cubic-bezier(1, -0.01, 0.87, 0.84) 400ms,
          height linear 300ms;
        padding: 8px 0;
      }
    }
  }
`;

export const SubNavigation = styled.nav`
  position: fixed;
  bottom: 4px;
  left: 4px;
  right: 4px;
  padding: 2px 4px;
  border-radius: var(--rounding-radius-xs, 8px);
  background: var(--fill-secondary);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  z-index: 1500;
  @media (min-width: 1024px) {
    bottom: auto;
    display: block;
    left: 0;
    top: 0;
    width: 256px;
    margin: 96px 0 0 48px;
    padding: 32px 0 0;
    background: transparent;
    backdrop-filter: blur(0px);
    z-index: 100;
  }

  .action,
  .headerM,
  .headerS,
  .headerL {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }
`;

export const LinksRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 44px;
  gap: 6px;
  @media (min-width: 1024px) {
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    height: auto;
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 10px;
  opacity: 0.64;
  transition: 300ms ease-out;
  cursor: pointer;
  &.active {
    opacity: 1;
    pointer-events: none;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    height: 48px;
    border-radius: var(--rounding-radius-xs, 8px);
    padding: 12px;
    &:hover {
      background: var(--fill-secondary);
    }
  }
`;

export const LinkText = styled.div`
  color: var(--text-primary);
  font-size: 10px;
  font-variation-settings: "wght" 500;
  line-height: 12px;
  letter-spacing: 0.5px;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
`;

export const Banner = styled.a`
  display: none;
  overflow: hidden;
  border-radius: var(--rounding-radius-xl, 24px);
  padding: var(--spacing-space-20, 20px) var(--spacing-space-20, 20px)
    var(--spacing-space-24, 24px) var(--spacing-space-20, 20px);
  position: relative;
  margin-top: 32px;
  isolation: isolate;
  background: #252222;
  box-shadow: 0px 4px 8px 0px rgba(37, 34, 34, 0.08),
    0px 8px 24px 0px rgba(37, 34, 34, 0.16);

  &.mobile {
    display: block;
  }

  p {
    color: #fff;
    padding-right: 20px;
  }

  @media (min-width: 1024px) {
    display: block;
    &.mobile {
      display: none;
    }
    p {
      padding-right: 14px;
    }
  }
`;

export const CloseBanner = styled.button`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  border: 0;
  outline: 0;
  background: transparent;
`;

export const AirdropBannerPicture = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 96px;
  object-fit: contain;
  z-index: -1;
`;
export const AirdropButton = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  display: flex;
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) var(--spacing-space-6, 6px);
  align-items: center;
  gap: var(--spacing-space-8, 8px);
  background: #c8ff26;
  .action {
    color: var(--text-primary-on-brand);
    /* color: #000; */
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }
  img {
    width: 24px;
    height: 24px;
  }
  &.sm {
    height: 24px;
    gap: 2px;
    padding: var(--spacing-space-4, 4px) var(--spacing-space-8, 8px)
      var(--spacing-space-4, 4px) var(--spacing-space-4, 4px);

    img {
      width: 16px;
      height: 16px;
    }
  }
`;
