import { RefObject, useEffect, useRef, useState } from "react";

export const useDebounce = <T>(
  value: T,
  opts: { delay?: number; raf?: boolean },
) => {
  const _value = useRef(value);
  const [pending, setPending] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (_value.current !== value) {
      setPending(true);
    }
    let cancel = false;

    _value.current = value;

    const callback = () => {
      if (!cancel) {
        setDebouncedValue(_value.current);
        setPending(false);
      }
    };

    opts.raf
      ? requestAnimationFrame(callback)
      : setTimeout(callback, opts.delay ?? 33);

    return () => {
      cancel = true;
    };
  }, [value, opts]);

  return [debouncedValue, pending] as const;
};

export function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T> | null,
  onClickOutsideClick: () => void,
) {
  useEffect(() => {
    const iframes = Array.from(document.querySelectorAll("iframe"))
      .filter((node) => {
        try {
          return node?.contentWindow?.document;
        } catch (e) {
          if (e instanceof DOMException) {
            /** ignore this error */
          } else if (e instanceof Error) {
            console.error(e.message);
          }

          return false;
        }
      })
      .map((node) => node?.contentWindow?.document)
      .filter((v) => !!v) as Document[];

    const listenerNodes = [document, ...iframes];

    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref &&
        ref.current &&
        event &&
        !ref.current.contains(event.target as Node)
      ) {
        onClickOutsideClick();
      }
    };

    listenerNodes.forEach((node) =>
      node.addEventListener("click", handleClickOutside),
    );

    return () => {
      listenerNodes.forEach((node) =>
        node.removeEventListener("click", handleClickOutside),
      );
    };
  }, [ref, onClickOutsideClick]);
}
