"use client";

import Noop from "@marginly/ui/components/helpers/noop";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";

import { PORTAL_DOM_ID } from "@/constants/common";

interface PortalProps {
  children: ReactNode;
}

const getDomNode = () =>
  typeof window !== "undefined"
    ? window.document.getElementById(PORTAL_DOM_ID)
    : undefined;

export default function Portal({ children }: PortalProps) {
  const node = getDomNode();
  return node ? createPortal(children, node) : <Noop />;
}
