"use client";
import { ReactNode, ReactPortal, useEffect, useState } from "react";

export const useClientMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}

export default function ClientOnly({
  _alwaysPlaceholder,
  children,
  fallback,
}: {
  /** @deprecated just for debug */
  _alwaysPlaceholder?: boolean;
  children: ReactNode | ReactPortal | null;
  fallback?: ReactNode;
}) {
  const hasMounted = useClientMounted();

  if (!hasMounted || _alwaysPlaceholder) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{children}</>;
}
