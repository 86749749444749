"use client";

import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  z-index: 98;
  position: fixed;
  top: 0;

  background: unset;
  box-shadow: unset;
  border-radius: unset;
  border: unset;

  @media (max-width: 1023px) {
    box-shadow: unset;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: unset;
  }
`;

export const Content = styled.div`
  width: calc(100% - 32px * 2);
  max-width: 1096px;
  height: 100%;
  margin: 0 auto;
  padding: 0 48px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    max-height: 100%;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 20px * 2);
    padding: 0 22px !important;
  }
`;
